import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
// import { FaHtml5 } from "react-icons/fa";
// import { MdGroups } from "react-icons/md";
import { FaPhoneVolume } from "react-icons/fa6";
import { BsCalculator } from "react-icons/bs";
import { RiTeamFill } from "react-icons/ri";

const HomePage = () => {
  return (
    <>
      <Header />

      <section className="sec1 ">
        <div className="">
          <div className="col-12 row">
            <div className="col-12 col-sm-6 order-2 order-sm-1 leftSide">
              <div className="headline">
                <p>
                  Let's Create <br /> Awesome Digital <br /> Product With Us.
                </p>
              </div>

              <div className="para">
                <p>
                  Your one stop solution for all your Mobile / WebApp <br />
                  and blockchain development needs
                </p>
              </div>

              <div className="getStartedBtn">
                <button>Get Started</button>
              </div>
            </div>

            <div className="col-12 col-sm-6 order-1 order-sm-2 rightSide">
              <img src={"/images/img1.gif"} alt="A man on the table" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec2 ">
        <div className="col-12 row ">
          <div className="col-12 col-sm-12 col-md-6  leftSide">
            <img
              src="/images/img2.gif"
              alt="two individuals talking about programming"
            />
          </div>

          <div className="col-12 col-sm-12 col-md-6 rightSide">
            <div>
              <div>
                <h1 className="title">Understanding your needs</h1>
              </div>

              <div className="desc">
                <p>
                  We are a dynamic software development agency dedicated to
                  empowering our clients to thrive in the online market. With a
                  focus on web, app and blockchain development, coupled with
                  expert search engine optimization and tailored social network
                  marketing strategies, we propel businesses towards success. As
                  a trusted tech consulting firm, our 4 years of proven
                  expertise ensure our clients receive cutting-edge solutions
                  and unparalleled support to achieve their goals.
                </p>
                <p>
                  We are a growing tech consulting firms among our clients, with
                  over 7 years of expertise and experienced developers.
                </p>
              </div>

              {/* <div className="getStartedBtn">
                <button>More About Us</button>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section
        className="sec3 "
        style={{ backgroundImage: "url(/images/background-banner.jpg)" }}
      >
        <div className="col-12 row">
          <div className="col-6 col-sm-3 col-md-3 col-lg-3">
            <h3>4+</h3>
            <p>Years of experience</p>
          </div>

          <div className="col-6 col-sm-3 col-md-3 col-lg-3">
            <h3>10+</h3>
            <p>Developers</p>
          </div>

          <div className="col-6 col-sm-3 col-md-3 col-lg-3 mt-3 mt-sm-0">
            <h3>50+</h3>
            <p>Projects complete </p>
          </div>

          <div className="col-6 col-sm-3 col-md-3 col-lg-3 mt-3 mt-sm-0">
            <h3>80+</h3>
            <p>Happy Clients</p>
          </div>
        </div>
      </section>

      <section className="sec4">
        <div className="text-center">
          <div className="firstTitle">
            <h6>SERVICES</h6>
          </div>

          <div className="secondTitle">
            <h6>Offered Services</h6>
          </div>

          <div className="thirdDesc">
            <p>
              Our ambitions reach for the moon and extend beyond the Milky Way
              galaxy. With determination as our compass, <br /> we chart a
              course towards limitless horizons.
            </p>
          </div>
        </div>

        <div className="col-12  row servicesBox">
          <div className="col-6 col-sm-6   col-md-4 mt-3 ">
            <div className="card">
              {" "}
              <img src="/images/icons/nkj.gif" alt="web Development " />{" "}
              <h3>Web Development</h3>
              <p>
                Unlock your business's full potential by harnessing the power of
                the online realm. From sleek one-page websites to robust
                e-commerce platforms and beyond, we bring your most ambitious
                visions to life with precision and flair.
              </p>
            </div>
          </div>

          <div className="col-6 col-sm-6  col-md-4 mt-3  ">
            <div className="card">
              {" "}
              <img src="/images/icons/html.gif" alt="web Development " />{" "}
              <h3 className="title">App | Game Development</h3>
              <p className="desc">
                Meet your business's app and game development needs with our
                dedicated team of experienced developers, delivering high-end
                UI/UX solutions tailored to your vision.
              </p>
            </div>
          </div>

          <div className="col-6 col-sm-6  col-md-4 mt-3 ">
            <div className="card">
              {" "}
              <img
                src="/images/icons/promote.gif"
                alt="web Development "
              />{" "}
              <h3 className="title">SEO Services & Marketing</h3>
              <p className="desc">
                Expand your footprint with our SEO and Social Media Marketing
                services, strategically targeting your potential users through
                web page optimization and tailored marketing strategies.
              </p>
            </div>
          </div>

          <div className="col-6 col-sm-6  col-md-4  mt-3 ">
            <div className="card">
              {" "}
              <img src="/images/icons/nkj.gif" alt="web Development " />{" "}
              <h3 className="title">Blockchain Development</h3>
              <p className="desc">
                Get all your blockchain development needs from smart contract
                development to NFTs to complex blockchain games delivered by our
                team.
              </p>
            </div>
          </div>

          <div className="col-6 col-sm-6  col-md-4  mt-3 ">
            <div className="card">
              {" "}
              <img
                src="/images/icons/consultation.gif"
                alt="web Development "
              />{" "}
              <h3 className="title">Tech Consulting</h3>
              <p className="desc">
                Elevate your business's technical landscape with us. Our
                dedicated team of managers not only understands your needs but
                also optimizes workflow and boosts customer retention, ensuring
                seamless success every step of the way.
              </p>
            </div>
          </div>

          <div className="col-6 col-sm-6  col-md-4  mt-3 ">
            <div className="card">
              {" "}
              <img
                src="/images/icons/paint-roller.gif"
                alt="web Development "
              />{" "}
              <h3 className="title">Branding & Design</h3>
              <p className="desc">
                Hand in hand with our backend team, our frontend developers and
                designers are dedicated to shaping your online presence and
                branding. Together, we craft compelling designs that leave a
                lasting impression.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sec5">
        <div className="text-center">
          <div className="firstTitle">
            <h6>PROJECTS</h6>
          </div>

          <div className="secondTitle">
            <h6>Development Ideas</h6>
          </div>

          <div className="thirdDesc">
            <p>
              Increase your online presence with high quality optimized websites
            </p>
          </div>
        </div>

        <div className="col-12  row projectsBox">
          <div className="col-12 col-sm-6 col-md-4 mt-3 mt-md-0 ">
            <div className="card">
              {" "}
              <div className="imgBox">
                <img src="/images/projects/sinbit.png" alt="web devlp" />{" "}
              </div>
              <div className="content">
                <h3>SinBit Trades</h3>
                <p>HTML , CSS </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-4 mt-3 mt-md-0">
            <div className="card">
              {" "}
              <div className="imgBox">
                <img src="/images/projects/sinbit.png" alt="web devlp" />{" "}
              </div>
              <div className="content">
                <h3>Sinbit Trades</h3>
                <p>HTML , CSS </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-4 mt-3 mt-md-0">
            <div className="card">
              {" "}
              <div className="imgBox">
                <img src="/images/projects/sinbit.png" alt="web devlp" />{" "}
              </div>
              <div className="content">
                <h3>Sinbit Trades</h3>
                <p>HTML , CSS </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec6">
        <div className="text-center">
          <div className="firstTitle">
            <h6>WE ENSURE TRUST</h6>
          </div>

          <div className="secondTitle">
            <h6>Why Choose Us ? </h6>
          </div>

          <div className="thirdDesc">
            <p>
              We comprehend the needs of businesses, whether small or large, and
              deliver robust solutions tailored to their requirements.
            </p>
          </div>
        </div>

        <div className="col-12  row servicesBox">
          <div
            className="col-12 col-sm-4  mt-3 mt-md-0 "
            title="Dedicated Team"
          >
            <div className="card">
              {" "}
              <div className="imgBox">
                {/* <img src="/images/icons/consultation.gif" alt="dsfasd" />{" "} */}
                {/* <MdGroups size={100} /> */}
                <RiTeamFill />
              </div>
              <h3>Dedicated Team</h3>
            </div>
          </div>

          <div
            className="col-12 col-sm-4 mt-3 mt-md-0 "
            title="24 &#xd7; 7 Service"
          >
            <div className="card">
              {" "}
              <div className="imgBox">
                {/* <img src="/images/icons/consultation.gif" alt="dsfasd" />{" "} */}
                <FaPhoneVolume />
              </div>
              <h3>24 &#xd7; 7 Service</h3>
            </div>
          </div>

          <div
            className="col-12 col-sm-4 mt-3 mt-md-0 "
            title="Personalized Pricing"
          >
            <div className="card">
              {" "}
              <div className="imgBox">
                {/* <img src="/images/icons/consultation.gif" alt="dsfasd" />{" "} */}
                <BsCalculator />
              </div>
              <h3>Personalized Pricing </h3>
            </div>
          </div>
        </div>
      </section>

      <section className="sec7">
        <div className="text-center">
          <div className="firstTitle">
            <h6>OUR TECH STACK</h6>
          </div>

          <div className="thirdDesc">
            <p>
              We build effective, robust and agile solutions <br /> for our
              clients{" "}
            </p>
          </div>
        </div>
        {/*  */}
        <div className="languagesBox col-12 row">
          <div className="col-3 col-sm-3  mt-4  ">
            <div className="techCard" title="Html">
              <div className="techImage">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/HTML5_Badge.svg/2048px-HTML5_Badge.svg.png"
                  alt="HTML"
                />
              </div>

              <h3>HTML</h3>
            </div>
          </div>

          <div className="col-3 col-sm-3  mt-4  ">
            <div className="techCard">
              <div className="techImage" title="Css">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/CSS3_logo.svg/2048px-CSS3_logo.svg.png"
                  alt="CSS"
                />
              </div>

              <h3>CSS</h3>
            </div>
          </div>

          <div className="col-3 col-sm-3  mt-4  ">
            <div className="techCard">
              <div className="techImage" title="JavaScript">
                <img
                  src="https://static.vecteezy.com/system/resources/previews/027/127/463/original/javascript-logo-javascript-icon-transparent-free-png.png"
                  alt="HTML"
                />
              </div>

              <h3>JavaScript</h3>
            </div>
          </div>

          <div className="col-3 col-sm-3  mt-4  ">
            <div className="techCard">
              <div className="techImage" title="React Native">
                <img
                  src="https://cdn.worldvectorlogo.com/logos/react-native-1.svg"
                  alt="HTML"
                />
              </div>

              <h3>React Native</h3>
            </div>
          </div>

          <div className="col-3 col-sm-3  mt-4  ">
            <div className="techCard" title="Mern Stack">
              <div className="techImage">
                <img
                  src="https://www.tridentspark.com/wp-content/uploads/2023/07/mern-stack-icon-1.png"
                  alt="HTML"
                />
              </div>

              <h3>Mern Stack</h3>
            </div>
          </div>

          <div className="col-3 col-sm-3  mt-4  ">
            <div className="techCard" title="Mean Stack">
              <div className="techImage">
                <img
                  src="https://www.lemosys.com/blog/wp-content/uploads/2022/09/60ce440d8fffcaf55396b276_meanstack.png"
                  alt="HTML"
                />
              </div>

              <h3>Mean Stack</h3>
            </div>
          </div>

          <div className="col-3 col-sm-3  mt-4  ">
            <div className="techCard" title="Django">
              <div className="techImage">
                <img
                  src="https://seeklogo.com/images/D/django-logo-4C5ECF7036-seeklogo.com.png"
                  alt="HTML"
                />
              </div>

              <h3>Django</h3>
            </div>
          </div>

          <div className="col-3 col-sm-3  mt-4  ">
            <div className="techCard" title="Sql / NoSql">
              <div className="techImage">
                <img
                  src="https://cdns.iconmonstr.com/wp-content/releases/preview/2012/240/iconmonstr-database-1.png"
                  alt="HTML"
                />
              </div>

              <h3>Sql / NoSql</h3>
            </div>
          </div>
        </div>
      </section>

      <section className="sec8">
        <div className="heading">
          <h3>Let's create big stories together</h3>
          <div style={{ borderBottom: "2px solid #000", width: "50%" }}></div>
        </div>

        <div className="paragraph">
          <p>
            We have stood the test of the time, and have helped brands create
            their finest online presence. SinTechWorks prides itself in driving
            qualified traffic, converting visitors, and using cutting-edge
            technology that measures effectiveness to deliver real results for
            our clients.
          </p>
        </div>

        <div title="Founder">
          <h6 style={{ fontWeight: "bold", fontSize: "18px" }}>
            {" "}
            Pranav Sinha
          </h6>
          <p>Founder</p>
        </div>

        <div
          className="contactBox"
          style={{
            backgroundImage: `url('/images/asdas.jpg')`,
            backgroundPosition: "contain",
            backgroundPosition: "50% 50%",
          }}
        >
          <div className="title">
            {" "}
            <h1>
              Have a project in Mind ? <br /> Lets get to work{" "}
            </h1>
          </div>

          <div className="row col-12">
            <div className="col-12 col-sm-12 col-md-6 order-2 order-md-1 mt-3 mt-md-0 ">
              <div>
                <div className="mb-3">
                  <label htmlFor="fullName" className="form-label">
                    Your Full Name
                  </label>
                  <input type="text" className="form-control" id="fullName" />
                </div>
              </div>

              <div>
                <div className="mb-3">
                  <label htmlFor="yourEmail" className="form-label">
                    Your Email
                  </label>
                  <input type="email" className="form-control" id="yourEmail" />
                </div>
              </div>

              <div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Your Contact No
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    className="form-control"
                    name="phone"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Digital Consultancy"
                      id="digitalCurrency"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="digitalCurrency"
                    >
                      Digital Consultancy
                    </label>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Web Design"
                      id="webDesign"
                    />
                    <label className="form-check-label" htmlFor="webDesign">
                      Web Designing
                    </label>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Web Development"
                      id="webDevelopment"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="webDevelopment"
                    >
                      Web Development
                    </label>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Search engine Optimization"
                      id="seo"
                    />
                    <label className="form-check-label" htmlFor="seo">
                      Search engine Optimization
                    </label>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Social Media Marketing"
                      id="socialMediaMarketing"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="socialMediaMarketing"
                    >
                      Social Media Marketing
                    </label>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="WebApp Development"
                      id="webAppDevelopment"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="webAppDevelopment"
                    >
                      WebApp Development
                    </label>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Block Chain Development"
                      id="blockChainDevelopment"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="blockChainDevelopment"
                    >
                      Block Chain Development
                    </label>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Android Development"
                      id="androidDevp"
                    />
                    <label className="form-check-label" htmlFor="androidDevp">
                      Android Development
                    </label>
                  </div>
                </div>
              </div>

              <div className="mb-3 mt-3">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  Project Details
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>

              <div>
                <button className="btn btn-primary btn-md"> Submit </button>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 order-1 order-md-2 ">
              <div>
                <img src="/images/img3.gif" width={"100%"} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default HomePage;
