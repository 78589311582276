import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="header">
      <div className="leftSide d-flex">
        <span>
          {" "}
          <img src="/images/companyLogo.jpg" alt="SinTechWorks Logo" />{" "}
        </span>
        <h1 className="brandName"> Sintech Works</h1>
      </div>
      <div className="rightSide">
        <Link to="#"> Home </Link>
      </div>
    </div>
  );
};

export default Header;
