import React from "react";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer ">
          <div className="col-12 row">
            <div className="col-6 col-md-3  ">
              <h3 style={{ fontWeight: "600" }} className="">
                SinTechWorks LLP
              </h3>
            </div>

            <div className="col-6 col-md-3">
              <h3>Email :</h3>

              <p>
                {" "}
                <a
                  href="mailto:sintechworks05@gmail.com"
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  sintechworks05@gmail.com
                </a>
              </p>
            </div>

            <div className="col-6 col-md-3">
              <h3>Phone: </h3>
              <p>+91 8757001984 </p>
              <p></p>
            </div>

            <div className="col-6 col-md-3">
              <h3>Address :</h3>
              <p>
                Choti Daulatpur , Gandhi Library <br /> Road Jamalpur Munger{" "}
                <br /> 811214 , Bihar India{" "}
              </p>
            </div>
          </div>
        </div>

        <div className="copyright">
          <p> Copyright &#169; 2024 SinTechWorks LLP </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
